import {
	ComponentIcon,
	InertiaIcon,
	ComponentEvolvedIcon,
} from '../components/symbols/icons';

const usages = [
	{
		title: 'Titre de ma carte',
		summary: '',
		examples: ['title Nouveau titre'],
	},
	{
		title: 'Commenter le code',
		summary: '',
		examples: [
			'//Nouveau commentaire',
			'/*Nouveau commentaire\nsur plusieurs\nlignes*/',
		],
	},
	{
		toolbarButtonText: 'Créer un composant',
		Icon: ComponentIcon,
		title: 'Pour créer un composant',
		summary: 'component Nom [Visibilité (axe Y), Maturité (axe X)]',
		examples: [
			'component Client [0.9, 0.5]',
			'component Tasse de thé [0.9, 0.5]',
		],
	},
	{
		toolbarButtonText: 'Créer un marché',
		Icon: ComponentIcon,
		title: 'Pour créer un marché',
		summary: 'component Nom [Visibilité (axe Y), Maturité (axe X)]',
		examples: [
			'market Client [0.9, 0.5]',
			'market Tasse de thé [0.9, 0.5]',
			'evolve Client 0.9 (market)',
		],
	},
	{
		toolbarButtonText: 'Inertia',
		Icon: InertiaIcon,
		title:
			"Inertie - composante susceptible d'être confrontée à une résistance au changement.",
		summary: 'component Nom [Visibilité (axe Y), Maturité (axe X)] inertia',
		examples: [
			'component Client [0.95, 0.5] inertia',
			'component Tasse de thé [0.9, 0.5] inertia',
			'market Tasse de thé [0.9, 0.5] inertia',
		],
	},
	{
		Icon: ComponentEvolvedIcon,
		title: 'Pour faire évoluer un composant',
		summary: 'evole Nom (axe X)',
		examples: ['evolve Client 0.8', 'evolve Tasse de thé 0.8'],
	},
	{
		title: 'Pour relier des composants',
		summary: '',
		examples: [
			'Composant parent -> Composant enfant',
			'Client -> Tasse de thé',
		],
	},
	{
		title: 'Pour indiquer un flux',
		summary: '',
		examples: [
			'Composant parent +<> Composant enfant',
			'Client +<> Tasse de thé',
		],
	},
	{
		title: 'Pour définir le composant en tant que pipeline :',
		summary: 'pipeline Nom du composant [Axe X (début), Axe X (fin)]',
		examples: ['pipeline Client [0.15, 0.9]', 'pipeline Client'],
	},
	{
		title: 'Pour indiquer un flux - composants passés uniquement',
		summary: '',
		examples: [
			'Composant parent +< Composant enfant',
			'Eau chaude +< Bouilloire',
		],
	},
	{
		title: 'To indicate flow - future components only',
		summary: '',
		examples: [
			'Composant parent +> Composant enfant',
			'Eau chaude +> Bouilloire',
		],
	},
	{
		title: 'Pour indiquer un flux - avec étiquette',
		summary: '',
		examples: [
			"Composant parent +'Texte à insérer'> Composant enfant",
			"Eau chaude+'$0.10'> Bouilloire",
		],
	},
	{
		title: 'Zone Explorateur.rice , Villageois.e, Plannificateur.rice',
		summary:
			"Ajouter des zones indiquant le type d'approche au travail qui soutient le développement des composants",
		examples: [
			'pioneers [<visibility>, <maturity>, <visibility2>, <maturity2>]',
			'settlers [0.59, 0.43, 0.49, 0.63]',
			'townplanners [0.31, 0.74, 0.15, 0.95]',
		],
	},
	{
		title: 'Composant Construire, Acheter, Sous-traiter',
		summary:
			"Mettre en évidence un composant dont la méthode d'exécution est la construction, l'achat ou l'externalisation.",
		examples: [
			'build <component>',
			'buy <component>',
			'outsource <component>',
			'component Client [0.9, 0.2] (buy)',
			'component Client [0.9, 0.2] (build)',
			'component Client [0.9, 0.2] (outsource)',
			'evolve Client 0.9 (outsource)',
			'evolve Client 0.9 (buy)',
			'evolve Client 0.9 (build)',
		],
	},
	{
		title: 'Lier une carte imbriquée à un composant',
		summary:
			'Ajouter un lien de référence à une carte imbriquée. Un composant devient un lien vers une autre carte Wardley.',
		examples: [
			"submap Composant [<visibilité>, <maturité>] url(Nom de l'url)",
			"url Nom de l'url [URL]",
			'submap Site internet [0.83, 0.50] url(url de la carte)',
			'url url de la carte [https://onlinewardleymaps.com/#clone:qu4VDDQryoZEnuw0ZZ]',
		],
	},
	{
		title: "Les étapes de l'évolution",
		summary: "Modifier les étiquettes des stades d'évolution sur la carte",
		examples: [
			'evolution 1.->2.->3.->4.',
			//Activités
			'evolution Phase 1 :🧪 Genèse -> Phase 2 : ⚒️ Sur mesure -> Phase 3 : 🛒 Produit (+location) -> Phase 4 : ⛽ Commodité (+service public)',
			//Pratiques
			'evolution  1. Nouvelles -> 2. Emergentes -> 3. Bonnes -> 4. Meilleures',
			//Données
			'evolution 1. Non-modélisées -> 2. Divergentes -> 3. Convergentes -> 4. Modélisées',
			//Connaissances
			'evolution 1. Concept -> 2. Hypothèses -> 3. Théories -> 4. Répandues',
		],
	},
	{
		title: "Étiquettes de l'axe Y",
		summary: "Modifier le texte des étiquettes de l'axe des ordonnées",
		examples: [
			"y-axis N'apparaitera pas -> N'apparaitera pas-> <- Plus profond dans la chaine de valeur",
			'y-axis Chaine de valeur->Invisible->Visible',
			"y-axis ->-> <= Chaine de valeur"
		],
	},
	{
		title: 'Ajouter des notes',
		summary: "Ajouter du texte à n'importe quel endroit de la carte",
		examples: [
			'note Texte de la note [0.9, 0.5]',
			'note +développement futur [0.9, 0.5]',
		],
	},
	{
		title: 'Styles disponibles',
		summary: "Modifier l'apparence d'une carte",
		examples: ['style wardley', 'style handwritten', 'style colour'],
	},
];

export default usages;
