export const defaultLabelOffset = { x: 5, y: -10 };
export const increasedLabelOffset = { x: 5, y: -20 };

export const EvoOffsets = {
	//Space is decompose in a 20 col grid
	ordinateLeft: 2.5, //line at the first 3 cols
	custom: 5,
	product: 8,
	commodity: 12.5,
	ordinateRight: 17,
};

export const DefaultMapObject = {
	title: '',
	elements: [],
	links: [],
	evolution: [],
	presentation: { style: 'plain' },
	methods: [],
	annotations: [],
};

export const EvolutionStages = {
	genesis: { l1: 'Genèse', l2: '' },
	custom: { l1: 'Sur-mesure', l2: '' },
	product: { l1: 'Produit', l2: '(+location)' },
	commodity: { l1: 'Commodité', l2: '(+utilitaire)' },
};

export const MapDimensions = {
	width: 500,
	height: 600,
};

export const ExampleMap = `
//--CONTEXT--
title Salon de thé

//x-axis
//Activités
evolution Phase 1 :🧪 Genèse -> Phase 2 : ⚒️ Sur mesure -> Phase 3 : 🛒 Produit (+location) -> Phase 4 : ⛽ Commodité (+service public)
//Pratiques
//evolution  1. Nouvelles -> 2. Emergentes -> 3. Bonnes -> 4. Meilleures
//Données
//evolution 1. Non-modélisées -> 2. Divergentes -> 3. Convergentes -> 4. Modélisées
//Connaissances
//evolution 1. Concept -> 2. Hypothèses -> 3. Théories -> 4. Répandues

//y-axis
//_/!\_Contrairement à ce qui est souvent affiché, il n'y a pas d'axe des ordonnées. La carte se suffit a elle même pour ça
y-axis ->-> <= Chaine de valeur
//--END-CONTEXT--
anchor Entreprise [0.95, 0.56]
anchor Public [0.94, 0.71]
component Tasse de Thé [0.78, 0.53] label [19, -4]
component Tasse [0.72, 0.73]
component Thé [0.63, 0.77]
component Eau Chaude [0.50, 0.76] label [3, -14]
component Eau [0.38, 0.82]
component Bouilloire [0.43, 0.34] label [-82, 2]
evolve Bouilloire 0.55 label [16, 7]
component Énergie [0.1, 0.7] label [-27, 20]
evolve Énergie 0.77 label [-12, 21]
Entreprise->Tasse de Thé
Public->Tasse de Thé
Tasse de Thé->Tasse
Tasse de Thé->Thé
Tasse de Thé->Eau Chaude
Eau Chaude->Eau
Eau Chaude->Bouilloire
Bouilloire->Énergie

annotation 1 [[0.43,0.49],[0.10,0.73] ] Standardiser la fourniture d'énergie permet aux Bouilloires d'évoluer plus rapidement
annotation 2 [0.44, 0.80] L'eau chaude est évidente et bien connue
annotations [0.61, 0.19]

note +une note générique est apparue [0.29, 0.46]

style wardley`;
