import React, { useRef, useEffect, useState } from 'react';
import { owmBuild } from '../../version';
import { ExampleMap } from '../../constants/defaults';
import {
	Alert,
	Modal,
	InputGroup,
	FormControl,
	Button,
	ButtonGroup,
	DropdownButton,
	Dropdown,
	Form,
	Container,
	Row,
	Col,
} from 'react-bootstrap';
import Usage from './Usage';

function Stories(props) {

	const {
		isEditorOpen,
		saveStoryClick,

	} = props;
	
	const [modalShow, setModalShow] = useState(false);
	const [textArea, setTextArea] = useState(null);
	const [stories, setStories] = useState([]);

	return (
<ButtonGroup aria-label="First group">
			<Button variant="primary" onClick={()=>{}} size="sm" >
				{"Précédent : Alt + <"}
			</Button>
			<Button variant="primary" onClick={()=>{}} size="sm">
				{"Suivant : Alt + >"}
			</Button>
			<Button variant="primary" onClick={() => setModalShow(true)} size="sm">
				Gérer les stories
			</Button>
			<Button variant="primary" onClick={() => null} size="sm">
				{isEditorOpen ? "Fermer l'éditeur" : "Ouvrir l'éditeur"}
			</Button>
			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">Votre stories</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Une story est le lien vers une carte. Votre story contient les cartes suivantes :</p>
					<Container fluid="md" className='p-0'>
						{stories.map((story, index) => {
							return (
								<Row key={index} className='mb-2'>
									<Col>Carte n°{index+1} : {story}</Col>
									<Col xs="auto">
	
									<ButtonGroup aria-label="Basic example" className='mx-2'>
									<Button  size="sm" variant="primary"
										onClick={() => {
											if (index > 0) {
												const temp = stories[index];
												stories[index] = stories[index - 1];
												stories[index - 1] = temp;
												setStories([...stories]);
											}
										}}
									>+</Button>
									<Button  size="sm" variant="primary"
										onClick={() => {
											if (index < stories.length - 1) {
												const temp = stories[index];
												stories[index] = stories[index + 1];
												stories[index + 1] = temp;
												setStories([...stories]);
											}
										}}
									>-</Button>
									</ButtonGroup>
										<Button variant="danger" size="sm" onClick={()=>{
											setStories(stories.filter((s, i) => i !== index))
										}}>Supprimer</Button>
			
									</Col>
								</Row>
							)
						})}
					 </Container>
				</Modal.Body>
				<Modal.Footer>
				<Form noValidate> 
					<Form.Control type="text" onChange={(e)=>{
						e.preventDefault();
						setTextArea(e.target.value);
					}}  placeholder="https://editor.wardleymaps.co/#...." />
				</Form>
				<Button variant="primary"
				onClick={() => {
					if(textArea.includes('https://editor.wardleymaps.co/#')){
						setStories([...stories, textArea])
					} else {
						setTextArea("")
					}
				}}
				>Ajouter un lien vers une carte</Button>
				<Button variant="secondary">Sauvegarder</Button>
				</Modal.Footer>
			</Modal>
			</ButtonGroup>
	);
}

export default Stories;
