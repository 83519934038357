import React from 'react';

function MapEvolution(props) {
	var geneMark =
		(props.mapDimensions.width / 20) * props.evolutionOffsets.ordinateLeft + 2;
	var custMark =
		(props.mapDimensions.width / 20) * props.evolutionOffsets.custom + 2;
	var prodMark =
		(props.mapDimensions.width / 20) * props.evolutionOffsets.product + 2;
	var commMark =
		(props.mapDimensions.width / 20) * props.evolutionOffsets.commodity + 2;

	return (
		<g
			id="Evolution"
			fontFamily='"Helvetica Neue",Helvetica,Arial,sans-serif'
			fontSize="13px"
			fontStyle="italic"
			transform={'translate(0,' + props.mapDimensions.height + ')'}
		>
			<line
				x1={
					(props.mapDimensions.width / 20) * props.evolutionOffsets.ordinateLeft
				}
				y1={0}
				x2={
					(props.mapDimensions.width / 20) *
					props.evolutionOffsets.ordinateRight
				}
				y2={0}
				stroke={props.mapStyleDefs.stroke}
				strokeWidth={props.mapStyleDefs.strokeWidth}
				markerEnd="url(#graphArrow)"
			/>

			<text
				x={
					(props.mapDimensions.width / 20) *
						props.evolutionOffsets.ordinateLeft +
					10
				}
				y={-props.mapDimensions.height + 15}
				textAnchor="start"
				fontStyle="normal"
				fontSize="11px"
				fontWeight="bold"
			>
				Inexploré
			</text>

			<text
				x={
					(props.mapDimensions.width / 20) *
						props.evolutionOffsets.ordinateRight -
					10
				}
				y={-props.mapDimensions.height + 15}
				textAnchor="end"
				fontStyle="normal"
				fontSize="11px"
				fontWeight="bold"
			>
				Industrialisé
			</text>

			<text x={geneMark} y="1em" textAnchor="start">
				{props.mapEvolutionStates.genesis.l1}
			</text>
			<text x={geneMark} y="2em" textAnchor="start">
				{props.mapEvolutionStates.genesis.l2}
			</text>
			<text x={custMark + 5} y="1em" textAnchor="start">
				{props.mapEvolutionStates.custom.l1}
			</text>
			<text x={custMark + 5} y="2em" textAnchor="start">
				{props.mapEvolutionStates.custom.l2}
			</text>
			<text x={prodMark + 5} y="1em" textAnchor="start">
				{props.mapEvolutionStates.product.l1}
			</text>
			<text x={prodMark + 5} y="2em" textAnchor="start">
				{props.mapEvolutionStates.product.l2}
			</text>
			<text x={commMark + 5} y="1em" textAnchor="start">
				{props.mapEvolutionStates.commodity.l1}
			</text>
			<text x={commMark + 5} y="2em" textAnchor="start">
				{props.mapEvolutionStates.commodity.l2}
			</text>
			<text
				x={
					(props.mapDimensions.width / 20) *
					props.evolutionOffsets.ordinateRight
				}
				y="1.8em"
				textAnchor="end"
				fontWeight="bold"
				fontStyle="normal"
			>
				Évolution
			</text>
			<text
				x={props.mapDimensions.height / 2}
				y={props.mapDimensions.width - 0}
				textAnchor="middle"
				//fontWeight="bold"
				fontStyle="normal"
				transform={`rotate(-90 ${0} ${0})`}
			>
				Espace du bien commun
			</text>
			<text
				x={props.mapDimensions.height / 2}
				y={0}
				textAnchor="middle"
				//fontWeight="bold"
				fontStyle="normal"
				transform={`rotate(-90 ${0} ${0})`}
			>
				Espace du bien social
			</text>
			<text
				x={props.mapDimensions.width / 2}
				y={(props.mapDimensions.height - 0) * -1}
				textAnchor="middle"
				//fontWeight="bold"
				fontStyle="normal"
			>
				Espace de l'économie
			</text>
		</g>
	);
}

export default MapEvolution;
