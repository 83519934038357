import React from 'react';
import Stories from './Stories';
import { ButtonToolbar, Container } from 'react-bootstrap';

const Breadcrumb = ({ currentUrl }) => {
	return (
		<Container fluid>
{/*	<ButtonToolbar
        className="justify-content-between"
        aria-label="Toolbar with Button groups"
      >
		   <Stories /> */}
			<ol
				className="breadcrumb mr-2 pr-1"
				data-testid="breadcrumb-list"
				style={{ justifyContent: 'end',backgroundColor: 'transparent' }}
			>
				<li className="breadcrumb-item " data-testid="breadcrumb-list-item">
					Lien de partage :{' '}
					<a
						href={currentUrl.indexOf('#') === -1 ? '#' : currentUrl}
						id="url"
						data-testid="breadcrumb-list-item-your-map"
					>
						{currentUrl.indexOf('#') === -1 ? '(unsaved)' : currentUrl}
					</a>
				</li>
			</ol>
	  {/* </ButtonToolbar> */}
		</Container>
	);
};

export default Breadcrumb;
