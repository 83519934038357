import React, { useRef, useEffect, useState } from 'react';
import { owmBuild } from '../../version';
import { ExampleMap } from '../../constants/defaults';
import {
	Alert,
	Modal,
	InputGroup,
	FormControl,
	Button,
	ButtonGroup,
	DropdownButton,
	Dropdown,
} from 'react-bootstrap';
import Usage from './Usage';

function Controls(props) {
	const [toggleAutoSave, setToggleAutoSave] = useState(false);
	const {
		saveOutstanding,
		saveMapClick,
		mutateMapText,
		setMetaText,
		newMapClick,
		downloadMapImage,
		currentUrl,
		setShowLineNumbers,
		showLineNumbers,
		setShowLinkedEvolved,
		showLinkedEvolved,
		isLoading,
	} = props;
	useEffect(() => {
		if (toggleAutoSave === true) {
			if (saveOutstanding === true) {
				saveMapClick();
			}
		}
	}, [saveOutstanding, saveMapClick, toggleAutoSave]);

	const clickAutoSave = () => {
		toggleAutoSave ? setToggleAutoSave(false) : setToggleAutoSave(true);
	};

	const example = () => {
		mutateMapText(ExampleMap);
		setMetaText('');
	};

	const [modalShow, setModalShow] = React.useState(false);
	const [modalShow2, setModalShow2] = React.useState(false);
	const [modalShow3, setModalShow3] = React.useState(false);

	const textArea = useRef(null);
	const copyCodeToClipboard = () => {
		textArea.current.select();
		document.execCommand('copy');
	};

	return (
		<React.Fragment>
			<Button variant="primary" onClick={() => setModalShow3(true)}>
				Aide
			</Button>
			<Modal
				show={modalShow3}
				onHide={() => setModalShow3(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">Aide</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Usage mapText={props.mapText} mutateMapText={mutateMapText} />
				</Modal.Body>
			</Modal>
			<Button
				id="example-map"
				onClick={example}
				type="button"
				variant="primary"
			>
				Exemple de carte
			</Button>
			<Button id="new-map" variant="primary" onClick={newMapClick}>
				Nouvelle carte
			</Button>
			<Button
				id="save-map"
				onClick={!isLoading ? saveMapClick : null}
				type="button"
				variant={saveOutstanding ? 'danger' : 'success'}
				disabled={isLoading}
			>
				{isLoading ? 'Chargement…' : 'Enregistrer'}
			</Button>

			<DropdownButton
				as={ButtonGroup}
				title="Autres"
				id="bg-nested-dropdown"
				alignRight
				variant="primary"
			>
				<Dropdown.Item eventKey="1" onClick={downloadMapImage}>
					Téléchargé comme PNG
				</Dropdown.Item>
				<Dropdown.Item eventKey="2" onClick={() => setModalShow(true)}>
					Obtenir un url de partage
				</Dropdown.Item>
				<Dropdown.Item
					eventKey="3"
					onClick={clickAutoSave}
					variant={toggleAutoSave ? 'success' : 'danger'}
				>
					{toggleAutoSave ? 'AutoSave est On' : 'AutoSave est Off'}
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Item
					eventKey="4"
					onClick={() => setShowLineNumbers(!showLineNumbers)}
					variant={'plain'}
				>
					{showLineNumbers
						? 'Cacher les numéros de ligne'
						: 'Afficher les numéros de ligne'}
				</Dropdown.Item>
				<Dropdown.Item
					eventKey="5"
					onClick={() => setShowLinkedEvolved(!showLinkedEvolved)}
					variant={'plain'}
				>
					{showLinkedEvolved
						? "Masquer les liaisons d'évolution"
						: "Afficher les liaisons d'évolution"}
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Item eventKey="1" onClick={() => setModalShow2(true)}>
					Crédits
				</Dropdown.Item>
			</DropdownButton>

			<Modal
				show={modalShow2}
				onHide={() => setModalShow2(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">Crédits</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Cette version de l'éditeur de cartes de Wardley
						www.onlinewardleymaps.com est mise à disposition par{' '}
						<span>
							{' '}
							<a
								href="https://www.linkedin.com/in/mathieu-jolly/"
								target="_blank" //eslint-disable-line react/jsx-no-target-blank
								rel="noopener"
							>
								Mathieu Jolly
							</a>
						</span>
						. L'auteur original d'onlinewardleymaps.com est{' '}
						<span>
							{' '}
							<a
								href="https://twitter.com/damonsk"
								target="_blank" //eslint-disable-line react/jsx-no-target-blank
								rel="noopener"
							>
								@damonsk
							</a>
						</span>
						.
					</p>
					<p>
						Les cartes de Wardley ont été inventées par Simon Wardley. Elles
						sont sous license ouverte creative common
						<span>
							{' '}
							<a
								href="https://creativecommons.org/licenses/by-sa/4.0/deed.fr"
								target="_blank" //eslint-disable-line react/jsx-no-target-blank
								rel="noopener"
							>
								CC BY-SA 4.0.
							</a>{' '}
						</span>
						Pour en savoir en savoir plus, voir{' '}
						<a target="blank" href="https://fr.wardleymaps.co">
							le livre de Simon
						</a>
						.
					</p>
					<p>
						Les espaces du bien commun et du bien sociale adaptés aux cartes
						viennent d'une
						<span>
							{' '}
							<a
								href="https://twitter.com/swardley/status/1526539019752022016"
								target="_blank" //eslint-disable-line react/jsx-no-target-blank
								rel="noopener"
							>
								discussion
							</a>{' '}
						</span>
						entre @ajbouh, @jdcarlston et @swardley (comptes Twitter). Ces deux
						espaces peuvent aussi être nommées espace de Carlston et de
						Bouhenguel.
					</p>
				</Modal.Body>
			</Modal>

			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						URL de partage
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Vous pouvez partager cette URL avec d'autres personnes pour leur
						permettre de créer une nouvelle carte en utilisant cette carte comme
						état initial.
					</p>
					<p>
						Toute modification apportée par d'autres ne sera pas reflétée dans
						cette carte.
					</p>
					{currentUrl === '(unsaved)' ? (
						<Alert variant="danger">
							Veuillez d'abord sauvegarder votre carte !
						</Alert>
					) : (
						<InputGroup className="mb-3">
							<FormControl
								ref={textArea}
								onChange={function() {}}
								aria-describedby="basic-addon1"
								value={currentUrl.replace('#', '#clone:')}
							/>
							<InputGroup.Append>
								<Button variant="primary" onClick={() => copyCodeToClipboard()}>
									Copier
								</Button>
							</InputGroup.Append>
						</InputGroup>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant={saveOutstanding ? 'danger' : 'success'}
						onClick={saveMapClick}
					>
						Enregistrer la carte
					</Button>
					<Button onClick={() => setModalShow(false)}>Fermer</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
}

export default Controls;
